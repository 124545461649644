module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dermatology Consultants of Asheville","short_name":"DCA","start_url":"/","background_color":"#fff","theme_color":"#234F9C","display":"minimal-ui","icon":"src/images/MountainOnly_square.svg","lang":"en","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0cb7fadda6886dfe1b950bc66e5cd03c"},
    }]
